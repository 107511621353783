<template>
    <div>
        <header-bar>
            <div slot='leading' @click="$router.back()">
                <i style="font-size: 20px" class="iconfont icon-back"></i>
            </div>
        </header-bar>
        <div id="container"></div>
        <div class="mask-disabled"></div>
        <!-- <div class="overlay" v-if='!flag'></div> -->
        <div class="mapInfo">
            <div class="f_flex address">
                <div class="addressTitle f_s0">地址：</div>
                <div>{{ mapInfo }}</div>
            </div>
            <button class="mapBtn btn btn_radius margin_auto theme_bg" @click='getLocation'>确定</button>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    data: () => ({
        mapInfo: null,
        AMap: null,
        AMapUI: null,
        lng: null,
        lat: null,
        flag: false,
        geoInfo: null
    }),
    mounted () {
        setTimeout (() => {
            this.initMap ()
        }, 0)
        this.backRefresh ()
        this.$route.meta.change = () => {
            this.backRefresh ()
        }
    },
    methods: {
        ...mapActions ([
            'set_geo'
        ]),
        initMap () {
            this.AMap = window.AMap
            this.AMapUI = window.AMapUI
            if (this.AMapUI && this.AMap) {
                // console.log (this.lat, this.lng);
                this.initFn ()
            } else {
                setTimeout (() => {
                    this.initMap ()
                }, 200)
            }
        },
        geo (AMapUI, AMap, lng, lat) {
            const self = this
            AMapUI.loadUI (['misc/PositionPicker'], (PositionPicker) => {
                const mapConfig = {
                    zoom: 16,
                    scrollWheel: false,
                }
                if (lng && lat) {
                    mapConfig.center = [lng, lat]
                }
                const map = new AMap.Map ('container', mapConfig)
                
                
                setTimeout (() => {
                    map.plugin ('AMap.Geolocation', function () {
                        var geolocation = new AMap.Geolocation ({
                            enableHighAccuracy: true,//是否使用高精度定位，默认:true
                            timeout: 4000,          //超过10秒后停止定位，默认：无穷大
                            maximumAge: 0,           //定位结果缓存0毫秒，默认：0
                            convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
                            showButton: true,        //显示定位按钮，默认：true
                            buttonPosition: 'LT',    //定位按钮停靠位置，默认：'LB'，左下角
                            buttonOffset: new AMap.Pixel (10, -200),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                            showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
                            showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
                            panToLocation: true,     //定位成功后将定位到的位置作为地图中心点，默认：true
                            zoomToAccuracy: true,      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                            zoomEnable: false,
                            dragEnable: false,
                        });
                        map.addControl (geolocation);
                        self.flag ? geolocation.getCurrentPosition () : null
                        // AMap.event.addListener(geolocation, 'complete', onComplete);//返回定位信息
                        // AMap.event.addListener(geolocation, 'error', onError);      //返回定位出错信息
                    });
                }, 200)
                
                
                const positionPicker = new PositionPicker ({
                    mode: 'dragMap',
                    map: map
                });
                
                positionPicker.on ('success', (positionResult) => {
                    this.geoInfo = positionResult
                    this.mapInfo = positionResult.address
                });
                positionPicker.on ('fail', (positionResult) => {
                    console.log (positionResult);
                    this.mapInfo = null
                });
                
                positionPicker.start ();
                map.panBy (0, 1);
                
            });
            
        },
        initFn () {
            let AMapUI = window.AMapUI
            let AMap = window.AMap
            const lng = this.lng || window.geoLocation[0] || null
            const lat = this.lat || window.geoLocation[1] || null
            this.geo (AMapUI, AMap, lng, lat)
        },
        location (AMap, cb) {
            AMap.plugin ('AMap.Geolocation', function () {
                var geolocation = new AMap.Geolocation ({
                    enableHighAccuracy: true,//是否使用高精度定位，默认:true
                    timeout: 10000,       //超过10秒后停止定位，默认：5s
                    showButton: true,
                    buttonPosition: 'RB',    //定位按钮的停靠位置
                    buttonOffset: new AMap.Pixel (100, 200),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                    zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点
                    zoomEnable: false,
                    dragEnable: false,
                });
                AMap.addControl (geolocation);
                geolocation.getCurrentPosition ((status, result) => {
                    if (status == 'complete') {
                        console.log ('定位成功', result, result.position);
                        const mapConfig = {
                            zoom: 16,
                            scrollWheel: false
                        }
                        mapConfig.center = [result.position.lng, result.position.lat]
                        cb && cb (mapConfig)
                    } else {
                        console.log ('定位失败', result);
                    }
                });
            });
        },
        getLocation () {
            this.set_geo (this.geoInfo)
            // console.log (this.geoInfo);
            this.$router.back ()
        },
        backRefresh () {
            const {flag, lng, lat} = this.$route.query
            this.lng = lng
            this.lat = lat
            this.flag = flag === 'true'
        }
    },
}
</script>

<style lang="less" scoped>
#container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.mask-disabled{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 200px);
}
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
}

.mapInfo {
    position: absolute;
    width: 100%;
    height: 200px;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: #fff;
    border-radius: 12px 12px 0 0;
    box-sizing: border-box;
    padding: 24px;
    box-shadow: 0 0 10px #eee;
    
    .address {
        font-size: 15px;
        color: #666;
    }
    
    .addressTitle {
        width: 60px;
        color: #333;
        font-weight: 500;
    }
    
    .mapBtn {
        width: 80%;
        left: 10%;
        position: absolute;
        bottom: 20px;
    }
}

</style>
